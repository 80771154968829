
.title-word {
  font-family: var(--font-wow);
  font-weight: 800;
  font-size: 3vw;  
  animation: color-animation 20s linear infinite;
  @media #{$smlg-device} {
    font-size: 6vw;
  }
  
}
  .title-sylab-1 {
    --color-1: #0078D7;
    --color-2: #0078D7;
    --color-3: #0078D7;
    --color-4: #0078D7;
    --color-5: #36454F;
    --color-6: #36454F;
    --color-7: #009688;
    --color-8: #009688;
  }
  .title-sylab-2 {
    --color-1: transparent;
    --color-2: #0078D7;
    --color-3: #0078D7;
    --color-4: #0078D7;
    --color-5: #36454F;
    --color-6: #36454F;
    --color-7: #009688;
    --color-8: #009688;
  }
  .title-sylab-3 {
    --color-1: transparent;
    --color-2: transparent;
    --color-3: #0078D7;
    --color-4: #0078D7;
    --color-5: #36454F;
    --color-6: #36454F;
    --color-7: #009688;
    --color-8: #009688;
  }
  .title-sylab-4 {
    --color-1: transparent;
    --color-2: transparent;
    --color-3: transparent;
    --color-4: #0078D7;
    --color-5: #36454F;
    --color-6: #36454F;
    --color-7: #009688;
    --color-8: #009688;
  }
  .title-sylab-5 {
    --color-1: transparent;
    --color-2: transparent;
    --color-3: transparent;
    --color-4: transparent;
    --color-5: #36454F;
    --color-6: #36454F;
    --color-7: #009688;
    --color-8: #009688;
  }
  .title-sylab-6 {
    --color-1: transparent;
    --color-2: transparent;
    --color-3: transparent;
    --color-4: transparent;
    --color-5: transparent;
    --color-6: transparent;
    --color-7: #009688;
    --color-8: #009688;
  }
  .title-dot {
    --color-1: transparent;
    --color-2: transparent;
    --color-3: transparent;
    --color-4: transparent;
    --color-5: transparent;
    --color-6: transparent;
    --color-7: transparent;
    --color-8: #ec8442;
  }
  @keyframes color-animation {
    0%    {color: var(--color-1)}
    5%    {color: var(--color-2)}
    10%    {color: var(--color-3)}
    15%    {color: var(--color-4)}
    20%    {color: var(--color-5)}
    21%    {color: var(--color-6)}
    26%    {color: var(--color-6)}
    31%    {color: var(--color-6)}
    36%    {color: var(--color-6)}
    45%    {color: var(--color-7)}
    80%   {color: var(--color-8)}
    100%  {color: var(--color-8)}
  }
  
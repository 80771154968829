/* Popup overlay for video analysis */
.video-analyzer-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent grayish background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-content {
    background: rgba(255, 255, 255, 0.95);
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 1200px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 18px;
    width: 18px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
    z-index: 10000; /* Ensuring the button is always visible */
}

.video-container {
    width: 60%; /* Video on the left half */
    padding: 20px;
}

.video-container video {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
}

.analysis-results {
    width: 35%;
    margin: 20px 20px 20px 20px;
    // margin-left: 20px;
    // margin-top: 12px;
    // margin-right: 12px;
    // margin-bottom: 12px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    max-height: 650px; /* Limit height of results on large screens */
    overflow:auto;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.analysis-results button {
    content: "\a ";
    white-space: pre;
    border-radius: 12px;
    display: block;
    margin-top: 10px;
}

.analysis-results h4 {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    color: #333;
}

.analysis-results p {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    color: #444;
}

.vision-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.vision-grid .thumbnail {
    width: 30%;
    margin: 10px;
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    transition: transform 0.2s ease-in-out;
}

.vision-grid .thumbnail img {
    width: 100%;
    border-radius: 30px;
    transition: transform 0.5s ease;
}

.vision-grid .thumbnail:hover img {
    transform: scale(1.2);
}
.vision-grid .thumbnail.active  {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.74);
}

.vision-grid .thumbnail.active img {
    border: 3px solid var(--color-primary);
}

.play-icon-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    padding: 20px;
}

.loading {
    font-size: 18px;
    font-style: italic;
    color: gray;
    text-align: center;
}


/* MOBILE SPECIFIC STYLES */
@media #{$sm-layout} {
    .popup-overlay {
        flex-direction: column;
        
    }

    .popup-content {
        width: 90%; /* Ensure full width on mobile */
        max-height: 75%; /* Ensure nearly full heiht on mobile */
        flex-direction: column; /* Stack video and analysis results vertically */
    }

    .video-container {
        width: 100%; /* Video full width on mobile */
        padding: 10px;
    }

    .analysis-results {
        width: 100%; /* Full width on mobile */
        margin-left: 0;
        margin-top: 20px;
        max-height: 240px; /* Limit height of results on small screens */
        font-size: 12px; /* Adjust font size for mobile */
        overflow:auto;
    }

    .analysis-results h4 {
        font-size: 22px;
    }

    .analysis-results p {
        font-size: 17px;
    }

    .vision-grid {
        flex-direction: column; /* Stack thumbnails for mobile */
    }

    .vision-grid .thumbnail {
        width: 90%; /* Thumbnails take full width on mobile */
        margin: 10px auto;
    }

    .close-btn {
        top: 10px;
        right: 10px;
        font-size: 20px; /* Adjust close button size */
        height: 12px;
        width: 12px;
    }
}
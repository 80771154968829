/*-------------------------
   Footer Styles
-------------------------*/
.footer-area {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // justify-content: center;
    align-items: center;
    justify-items: center;
    // padding-top: 10px;
    // margin-bottom: 80px;
    width: 100%;
    height: auto;
    background-color: var(--dark-bg-color);
}
// .link{
//     color: #009688;
// };

.footer-top {
    padding-bottom: 70px;
    @media #{$md-layout} {
        padding-bottom: 50px;
    }
}
.footer-logo {
    // justify-content:space-around;
    // margin:0 auto;
    // flex-shrink:0;
    // max-width: fit-content;
    // margin-left: auto;
    justify-self: center;
    margin-top: -3.75vh;
    img {transform: scale(1.3);}
}

.footer-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content:space-between ;
    background-color: var(--dark-bg-color);

}

.footer-social-link {
    ul {
       display: flex;
       align-items: center; 
       justify-content: space-between;
       margin: 0 -5px;
       li {
           a {
                color: var(--color-gray-3);
                font-size: 30px;
                padding: 5px;
                @media #{$md-layout} {
                   font-size: 20px;
                }
                &:hover {
                    color: var(--color-link);
                }
           }
       }
    }
}

.footer-widget {
    margin-bottom: 80px;
    @media #{$sm-layout} {
        margin-bottom: 50px;
    }
    .widget-title {
        font-weight: 700;
    }
    &.border-end {
        border-right-color: var(--color-ghost) !important;
        padding-right: 80px;
        margin-right: 40px;
        @media #{$smlg-device} {
            border: none !important;
            padding-right: 0;
            margin-right: 0;
        }
    }
}

.footer-newsletter {
    .title {
        margin-bottom: 20px;
        letter-spacing: -0.045em;
    }
}

.footer-menu-link {
    ul {
        margin: -8px 0;
    }
    li {
        a {
            color: var(--color-body);
            padding: 8px 0;
            font-size: 18px;
            line-height: 1.5;
            @media #{$md-layout} {
                font-size: 16px;
            }
            &:hover {
                color: var(--color-link);
            }
        }
    }
}

.footer-bottom {
    display:flex;
    align-items: normal;
    width: 100%;
    // border-top: 1px solid var(--color-ghost);
    background-color: var(--dark-bg-color);
    padding: 25px 0;
    .legal {
        font-size: var(--font-body-sm);
        width: 90%;
        margin: auto;
    };
}
.footer-copyright {
    @media #{$sm-layout} {
        text-align: center;
        margin-bottom: 10px;
    }
    .copyright-text {
        color: var(--color-gray-1);
        a {
            color: var(--color-primary) !important;
            &:hover {
                color: var(--color-link) !important;
            }
        }
    }
}
.footer-bottom-link {
    text-align: center;
    @media #{$sm-layout} {
        text-align: center;
    }
    ul {
        margin: 0 -20px;
        li {
            padding: 0 20px;
            display: inline-block;
            position: relative;
            &::after {
                content: "";
                height: 4px;
                width: 4px;
                background-color: var(--color-gray-4);
                border-radius: 50%;
                position: absolute;
                top: 53%;
                right: -5px;
                transform: translateY(-50%);
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            a {
                color: var(--color-primary) !important;
                &:hover {
                    color: var(--color-link) !important;
                }
            }
        }
    }
}

.footer-dark {
    //border-top-color: var(--color-body);
    border-top: 1px solid var(--color-ghost);
    width: 100%;
    // display: flex;
    // justify-content: space-between;
    background-color: var(--dark-bg-color);
    padding-top: 0;
    // .footer-bottom {
    //     border-top-color: var(--color-body);
    // }
    .footer-copyright {
        .copyright-text {
            padding-left: 100px;
            @media #{$smlg-device} {
                padding-left: 5px;
            }
            display: inline-flex;
            color: var(--color-gray-2);
            a {
                color: var(--color-primary) !important;;
                &:hover {
                    color: var(--color-link) !important;
                }
            }
        }

    } 
    .footer-bottom-link {
        padding-right: 100px;
        @media #{$smlg-device} {
            padding-right: 5px;
        }
        ul {
            li {
                a {
                    color: var(--color-primary) !important;;
                    &:hover {
                        color: var(--color-link) !important;
                    }
                }
                &:after {
                    background-color: var(--color-gray-2);
                }
            }
        }
    }
}
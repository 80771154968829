/*-------------------------
   Call To Action
-------------------------*/
.call-to-action-area {
    padding: 140px 0 100px;
    position: relative;
    z-index: 1;
    @media #{$smlg-device} {
       padding: 100px 0;
    }
    @media #{$md-layout} {
       padding: 80px 0;
    }
    @media #{$sm-layout} {
       padding: 60px 0 40px;
    }
    &:before {
        content: "";
        height: 730px;
        width: 100%;
        background-color: var(--color-primary);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: -1;
        @media #{$md-layout} {
            height: 500px;
        }
        @media #{$sm-layout} {
            height: 480px;
        }
        @media #{$large-mobile} {
            height: 400px;
        }
    }
}
.call-to-action {
    display: flex;
    justify-content: center;
    justify-items: center;
    // border-bottom: 1px solid #e7e7e7;
    .section-heading {
        margin-bottom: 65px;
        @media #{$sm-layout} {
            margin-bottom: 50px;
        }
        span {
            color: var(--color-accent1);
        }
        .title {
            width: 80%;
            margin: 0 auto 20px;
            @media #{$md-layout} {
                width: 100%;
            }
        }
    }
    .thumbnail {
        position: relative;
        text-align: center;
        .larg-thumb {
            display: inline-block;
            margin-right: -180px;
            @media #{$md-layout} {
                margin-right: -130px;
            }
            @media #{$sm-layout} {
                margin-right: -100px;
            }
             @media #{$large-mobile} {
                margin-right: -50px;
             }
            img {
                @media #{$smlg-device} {
                    width: 90%;
                }
                @media #{$md-layout} {
                    width: 70%;
                }
                @media #{$sm-layout} {
                    width: 50%;
                }
                @media #{$large-mobile} {
                    width: 50%;
                }
            }
        }
        .small-thumb {
            li {
                position: absolute;
                img {
                    @media #{$smlg-device} {
                        width: 90%;
                    }
                    @media #{$md-layout} {
                        width: 70%;
                    }
                    @media #{$sm-layout} {
                        width: 35%;
                    }
                }
                &.shape-1 {
                    bottom: 0;
                    left: 155px;
                    @media only screen and (min-width: 1200px) and (max-width: 1299px) {
                      left: 90px;  
                    }
                    @media #{$smlg-device} {
                        left: 15px;
                    }
                    @media #{$md-layout} {
                        left: -50px;
                    }
                    @media #{$sm-layout} {
                        left: -70px;
                    }
                    @media #{$large-mobile} {
                        left: -125px;
                    }
                }
               
                &.shape-2 {
                    bottom: 0;
                    right: 80px;
                    @media only screen and (min-width: 1200px) and (max-width: 1299px) {
                      right: 30px;  
                    }
                    @media #{$smlg-device} {
                        bottom: 0;
                        right: -60px;
                    }
                    @media #{$md-layout} {
                        right: -95px;
                    }
                    @media #{$sm-layout} {
                        right: -110px;
                    }
                    @media #{$large-mobile} {
                        right: -130px;
                    }
                }
            }
        }
    }
}
.estimate-form {
    display: flex;
    flex-direction: column;
}

.estimate-form label {
    margin-bottom: 10px;
}

.estimate-form input[type="range"] {
    width: 100%;
    color: #D3D3D4;
}

// .estimate-form button {
//     padding: 8px 16px;
//     background-color: blue;
//     color: white;
//     border: none;
//     cursor: pointer;
// }

.form-element {
    font-size: 22px;
    color: var(--dark-body-text);
    background-color: transparent; 
    border-radius: 20px;
    border: 1px solid;
    border-color: #525260;
    width: 100%;
}
.select-option {
    font-size: 15px;
    font-family: "Futura Book" !important;
    color: var(--dark-body-text);
    background-color: #1d2951; 
    border-radius: 20px;
    border: 1px solid;
    border-color: #525260;
    width: 100%;
}
// .select {
//     font-size: 22px;
//     color: var(--dark-body-text);
//     background-color: "transparent"; 
//     border-radius: "20px";
//     border: "1px solid";
//     border-color: "#525260";
//     width: "100%";
//     &__menu {
//         margin: 0.125rem auto;
//       }
    
//       &__option {
//         background-color: "transparent";
    
//         &--is-focused {
//           background-color: #2e64e6;
//         }
//       }
    
//       &__group {
//         padding: 0;
//       }
    
//       &__menu-portal {
//         border: 1px solid darkblue;
//       }
// }
.btn-estimate{
    height: 8vh;
    border-radius: 30px !important;
    margin-bottom: 8vh !important;
    margin-bottom: 2vh !important;
    &:disabled{
        background-color: #6968ab;
        color:gray;
    }

}

// .switch {
//     position: relative;
//     display: inline-block;
//     width: 60px;
//     height: 34px;
//     outline: none;
//   }
//   .switch input {
//     position: absolute;
//     top: -99999px;
//     left: -99999px;
//   }
//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     -webkit-transition: .4s;
//     transition: .4s;
//     border-radius: 34px;
//   }
//   .slider:before {
//     position: absolute;
//     height: 26px;
//     width: 26px;
//     left: 4px;
//     bottom: 4px;
//     background-color: white;
//     -webkit-transition: .4s;
//     transition: .4s;
//     border-radius: 50%;
//   }
//   input:checked + .slider {
//     background-color: #2196F3;
//   }
//   input:focus + .slider {
//     box-shadow: 0 0 1px #2196F3;
//   }
//   input:checked + .slider:before {
//     -webkit-transform: translateX(26px);
//     -ms-transform: translateX(26px);
//     transform: translateX(26px);
//   }
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 8px;
  }
  .checkbox {
    display: none;
    width: 15%;
  }
  .label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
  }
  .inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
    float: left;
    width: 50%;
    height: 34px;
    padding: 0;
    line-height: 34px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
  }
  .inner:before {
    content: "Yes";
    font-size: 22px;
    padding-left: 10px;
    background-color: #2e64e6;
    color: #fff;
  }
  .inner:after {
    content: "No";
    font-size: 22px;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  .switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
    margin-left: 0;
  }
  .checkbox:checked + .label .switch {
    right: 0px;
  }

input[type="range"] {
    // -webkit-appearance: none;
    accent-color: #2e64e6;
  
}

.estimate-confirmation {
    margin-top: 4vh;
}
// input[type=range]::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     accent-color: #2e64e6;
//   }

// /* All the same stuff for Firefox */
// input[type=range]::-moz-range-thumb {
//     accent-color: #2e64e6;
//   }
  
//   /* All the same stuff for IE */
//   input[type=range]::-ms-thumb {
//     accent-color: #2e64e6;
//   }


/*-------------------------
   Brand
-------------------------*/
.brand-wrap-area {
    padding: 90px 0;
}

.tile-space {
    display:flex !important;
    align-items: space-around !important;
}

.brand-grid {
    // padding: 10px 0;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    .thumbnail {
        overflow: hidden;
        border-radius: 30px 30px 0 0;
        position: relative;
        img {
            filter: grayscale(50%);
            opacity: 0.5;
            transition: var(--transition);
        }
    }
    &:hover, &.active {
        img {
            opacity: 1;
            filter: grayscale(0);
        }
    }
    .content {
        position: relative;
        display: flex;
        align-self: center;
        align-content: center;
        justify-content: center;
        height: 100%;
        display: flex;
        align-content: center;
        background-color: var(--color-white);
        border-radius: 0 0 30px 30px;
        padding: 40px 50px;
        @media only screen and (max-width: 1199px) {
            padding: 40px;
        }
        // @media #{$sm-layout} {
        //     padding: 30px;
        // }
        .title {
            letter-spacing: -0.045em;
            margin-bottom: 10px;
            a {
                &:hover {
                    color: var(--color-link);
                }
            }
        }
        .subtitle {
            align-self: center;
            text-align: center;
            span {
                // margin-right: 5px;
                // position: relative;
                &:after {
                    content: ",";
                }
                &:last-child {
                    margin-right: 0;
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    &.project-style-2 {
        box-shadow: none;
        position: relative;
        // margin-bottom: 40px;
        
        border-radius: 12px;
        img {
            border-radius: 12px;
        }
        
        .content {
            background-color: transparent;
            border-radius: 0;
            padding: 0;
            position: absolute;
            // top: 80px;
            align-self: center;
            // justify-self: center;
            margin: 0 auto;
            // max-width: fit-content;
            // margin-left: auto;

            // @media #{$md-layout} {
            //     top: 30px;
            //     left: 30px; 
            // }
            .subtitle {
                font-size: 25px;
                @media #{$smlg-device} {
                    font-size: 20px;

                }
                // margin-bottom: 15px;
            }
        }
    }
}
